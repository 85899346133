import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import LogoImage from "../../data/images/logo.svg"
import React from "react"

const Logo = () => {
    return (
        <Link to="/">
            <LogoImage />
        </Link>
    )
}
export default Logo
